export default {
  animateContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
  },

  container: {
    position: 'relative',
    background: '#003a47',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    overflow: 'hidden',
    outline: 'none',
    cursor: 'pointer',
  },
};

export const animationProps = {
  variants: {
    fadeIn: {
      opacity: 1,
      height: '100%',
      width: '100%',
      borderRadius: 0,
      transition: { duration: 0.25 },
    },
    fadeOut: {
      opacity: 0,
      height: 0,
      width: 0,
      borderRadius: 20,
      transition: {
        opacity: { duration: 0.4 },
        default: { duration: 0.1, delay: 0.4 },
      },
    },
  },
  initial: false,
};
