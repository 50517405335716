import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Desktop from './Desktop';
import Mobile from './Mobile';
import Splash from './Splash';
import useStyle from './styles';

const Layout = ({ location, children }) => {
  const classes = useStyle();
  const [ssrSplash, setSsrSplash] = useState(true);

  useEffect(() => {
    setSsrSplash(typeof window === 'undefined');
  }, []);

  return (
    <>
      <Desktop location={location}>{children}</Desktop>
      <Mobile location={location}>{children}</Mobile>
      {ssrSplash ? <div className={classes.ssrSplash} /> : <Splash />}
    </>
  );
};

Layout.propTypes = {
  location: PropTypes.shape({ hash: PropTypes.string }).isRequired,
  children: PropTypes.element.isRequired,
};

export default Layout;
