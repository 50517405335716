/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import logoSvg from '@/assets/images/stiftung-uro-logo-green.svg';

import Menu from './Menu';

const Desktop = ({ classes, children, location }) => (
  <div className={classes.container}>
    <div className={classes.leftSide}>
      <Link to="/">
        <img src={logoSvg} alt="logo" width={171} />
      </Link>
      <div className={classes.logoText}>
        <p>Forschungsförderung</p>
        <p>in der Urologie</p>
      </div>
    </div>
    <div className={classes.rightSide}>
      <Menu location={location}>{children}</Menu>
    </div>
  </div>
);

Desktop.propTypes = {
  location: PropTypes.shape({}).isRequired,
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired,
};

export default Desktop;
