import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import useStyle, { Item, parentAnimationProps, childAnimationProps } from './styles';

const Menu = ({ isOpenMenu, closeMenu }) => {
  const { dataJson } = useStaticQuery(graphql`
    {
      dataJson {
        menu {
          id
          title
          to
        }
      }
    }
  `);
  const classes = useStyle();

  return (
    <div className={classes.container}>
      <motion.div {...parentAnimationProps} animate={isOpenMenu ? 'enter' : 'exit'}>
        {dataJson.menu.map(({ id, title, to }) => (
          <Item key={id} id={id} to={to} onClick={closeMenu} {...childAnimationProps} className={classes.button}>
            {title}
          </Item>
        ))}
        <motion.div {...childAnimationProps} className={classes.logoText}>
          <p>Forschungsförderung</p>
          <p>in der Urologie</p>
        </motion.div>
      </motion.div>
    </div>
  );
};

Menu.propTypes = {
  isOpenMenu: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default Menu;
