import withStyles from 'react-jss';

const styles = {
  '@global': {
    'html, body, #___gatsby, #gatsby-focus-wrapper': {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    html: {
      boxSizing: 'border-box',
    },
    '*, *::before, *::after': {
      boxSizing: 'inherit',
      '-webkit-tap-highlight-color': 'transparent',
    },
    body: {
      margin: 0,
      padding: 0,
      fontSmoothing: 'antialiased',
      '-webkit-font-smoothing': 'antialiased',
      fontFamily: 'Work Sans',
      fontWeight: 400,
      fontSize: 15,
      lineHeight: 1.33,
      color: '#003a47',
      '-webkit-overflow-scrolling': 'touch',
      '& *::selection': {
        textShadow: 'none',
        color: '#fff',
        backgroundColor: 'rgba(0, 58, 71, 0.99)',
      },
    },
    input: {
      appearance: 'none',
      '-webkit-appearance': 'none',
      borderRadius: 0,
    },
  },
};

export default withStyles(styles)(() => null);
