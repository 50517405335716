import { createUseStyles } from 'react-jss';

export default createUseStyles(({ spacing, menu }) => ({
  container: {
    maxHeight: ({ totalCount }) => `calc(100% - ${totalCount * menu.item.height}px)`,
    flex: ({ isOpen }) => (isOpen ? '1 1 auto' : 0),
    overflowX: 'hidden',
    '@media (max-height: 500px)': {
      maxHeight: ({ totalCount }) => `calc(100% - ${totalCount * 35}px)`,
    },
  },

  content: {
    margin: [spacing * 5, spacing * 6],
  },
}));
