/* eslint-disable no-param-reassign, react/no-array-index-key */
import React, { Component } from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { getIconList, handlerIcons, throttle, AutoAnimate, CheckerUserTime, getHeight, getWidth } from './utils';
import Uicon from './Uicon';
import { animationProps } from './styles';

class Splash extends Component {
  injectCSS = throttle((x, y) => {
    const icons = handlerIcons(this.icons, x, y, this.size);

    this.iconRefs.forEach((img, i) => {
      const { corner } = icons[i];
      const inject = () => {
        if (img.current) {
          img.current.style.transform = `rotate(${corner || 0}deg)`;
        }
      };

      if (this.startMouseMove) {
        setTimeout(() => {
          inject();
        }, 100);
      } else {
        inject();
      }
    });
  }, 50);

  size = 102;

  height = getHeight() <= 1440 ? getHeight() : 1440;

  width = getWidth() <= 2560 ? getWidth() : 2560;

  constructor() {
    super();
    this.icons = getIconList(this.height, this.width, this.size);

    this.iconRefs = this.icons.map(() => React.createRef());

    this.autoAnimate = new AutoAnimate({
      handler: this.injectCSS,
      height: this.height,
      width: this.width,
      size: this.size,
      step: 10,
      duration: 4,
      onExit: this.stopAnimate,
    });

    this.checkerUserTime = new CheckerUserTime({
      timeOut: 1,
      onTimeOut: () => this.setState({ splash: false }),
    });

    this.state = {
      splash: true,
    };
  }

  componentDidMount() {
    window.addEventListener('mousemove', this.handleMouseMove);
    this.autoAnimate.start();
  }

  componentDidUpdate() {
    const { splash } = this.state;

    if (!splash) {
      window.removeEventListener('mousemove', this.handleMouseMove);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    this.stopAnimate();
  }

  handleMouseMove = ({ clientX, clientY }) => {
    this.startMouseMove = true;
    this.autoAnimate.stop();
    if (!this.checkerUserTime.timer) {
      this.checkerUserTime.start();
    } else {
      this.checkerUserTime.trigger();
    }
    this.injectCSS(clientX, clientY);
  };

  handleSplashEvents = () => {
    this.startMouseMove = null;
    this.stopAnimate();
  };

  stopAnimate = () => {
    this.autoAnimate.stop();
    this.checkerUserTime.stop();

    if (!this.startMouseMove) {
      this.setState({ splash: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { splash } = this.state;

    return (
      <motion.div
        onClick={this.handleSplashEvents}
        onKeyDown={this.handleSplashEvents}
        className={classes.animateContainer}
        role="button"
        tabIndex="-1"
        animate={splash ? 'fadeIn' : 'fadeOut'}
        {...animationProps}
      >
        <div className={classes.container}>
          {this.icons.map(({ ...props }, i) => (
            <Uicon key={i} ref={this.iconRefs[i]} {...props} size={this.size} />
          ))}
        </div>
      </motion.div>
    );
  }
}

Splash.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default Splash;
