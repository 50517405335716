export default {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: ({ size }) => size,
    width: ({ size }) => size,
    position: 'absolute',
    left: ({ x }) => x || 0,
    top: ({ y }) => y || 0,
  },
};
