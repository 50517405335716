import { flatten } from './helpers';

export class AutoAnimate {
  constructor({ handler, height, width, size, step, duration, onExit }) {
    this.handler = handler;
    this.height = height;
    this.width = width;
    this.size = size;
    this.step = step;
    this.onExit = onExit;
    this.iterate = 0;
    this.figure = this.buildFigure();
    this.time = (8300 * duration) / this.figure.length;
  }

  buildFigure() {
    const { height, width, size: offset } = this;

    if (height - 2 * offset < 1 || width - 2 * offset < 1) return [];
    const main = [width, height, width, height].map(
      (count, index) =>
        typeof window !== 'undefined' &&
        Array(count - 2 * offset)
          .fill(0)
          .map((_, i) => {
            switch (index) {
              case 0:
                return { x: offset + i, y: offset };
              case 1:
                return { x: width - offset, y: offset + i };
              case 2:
                return { x: width - offset - i, y: height - offset };
              case 3:
                return { x: offset, y: height - offset - i };
              default:
                return null;
            }
          })
    );
    return flatten(main);
  }

  start() {
    const run = () => {
      if (!this.timer) return;
      if (this.figure.length === 0) {
        this.stop();
        return;
      }
      if (this.iterate < this.figure.length) {
        const { x, y } = this.figure[this.iterate];
        this.handler(x, y);
        this.iterate += this.step;
      } else {
        this.stop();
      }

      this.timer = setTimeout(run, this.time);
    };

    this.timer = setTimeout(run, this.time);
  }

  stop() {
    if (this.timer) {
      Promise.resolve().then(() => clearTimeout(this.timer));
      this.timer = null;
      this.onExit();
    }
  }
}
