import { createUseStyles } from 'react-jss';

import uroIconSvg from '@/assets/images/stiftung-uro-logo-green.svg';

export default createUseStyles(({ spacing }) => ({
  container: {
    display: 'none',
    '@media (max-width: 800px)': {
      flex: '1 1 auto',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: spacing * 20.6,
    padding: [spacing * 3, spacing * 4.25, 0, spacing * 3.5],
  },

  wrapper: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 500,
    position: 'relative',
  },
  contentContainer: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: [0, spacing * 3.5, spacing * 3],
  },

  button: {
    padding: 0,
    border: 'none',
    background: 'none',
    outline: 'none',
    cursor: 'pointer',
  },

  buttonClose: {
    marginRight: spacing,
  },
  icon: {
    width: 124,
    height: 80,
    backgroundImage: `url(${uroIconSvg})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
}));

export const animationProps = {
  variants: {
    pose1: {
      opacity: 0,
      height: 1,
      transition: {
        height: { duration: 0.2, delay: 0.2 },
        opacity: { duration: 0.2 },
      },
    },
    pose2: {
      opacity: 1,
      height: 'auto',
      transition: {
        height: { duration: 0.2, delay: 0.2 },
        opacity: { duration: 0.2, delay: 0.2 },
      },
    },
  },
  initial: false,
};
