exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-spenden-js": () => import("./../../../src/pages/spenden.js" /* webpackChunkName: "component---src-pages-spenden-js" */),
  "component---src-pages-stiftungsrat-js": () => import("./../../../src/pages/stiftungsrat.js" /* webpackChunkName: "component---src-pages-stiftungsrat-js" */),
  "component---src-pages-stiftungszweck-js": () => import("./../../../src/pages/stiftungszweck.js" /* webpackChunkName: "component---src-pages-stiftungszweck-js" */)
}

