export default ({ menu, colors, text, spacing }) => {
  const { hover, click } = colors;

  return {
    link: {
      textDecoration: 'none',
    },

    title: {
      display: 'flex',
      width: '100%',
      paddingLeft: spacing * 6,
      alignItems: 'center',
      height: menu.item.height,
      cursor: 'pointer',
      outline: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      ...text.menuItem,
    },

    titlePseudo: {
      '&:hover': {
        backgroundColor: `${hover} !important`,
      },
      '&:active': {
        backgroundColor: `${click} !important`,
      },
      '&:focus': {
        outline: 'none',
      },
    },
    '@media (max-height: 499px)': {
      title: {
        height: 35,
      },
    },
  };
};
