import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import useStyles from './styles';

const ContentContainer = ({ totalCount, isOpen, location, children }) => {
  const classes = useStyles({ totalCount, isOpen });
  const { pathname, search } = location;
  const ref = useRef();

  useEffect(() => {
    if (ref.current) ref.current.scrollTop = 0;
  }, [pathname, search]);

  return (
    <div ref={ref} className={classes.container}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

ContentContainer.propTypes = {
  totalCount: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.element.isRequired,
};

export default ContentContainer;
