import React from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import { animationProps } from './utils';

const Title = ({ disabled, title, to, className, classNameWrapper }) => (
  <Link to={to} className={classNameWrapper}>
    <motion.button animate={disabled ? 'position1' : 'position2'} {...animationProps} className={className}>
      {title}
    </motion.button>
  </Link>
);

Title.propTypes = {
  disabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  classNameWrapper: PropTypes.string.isRequired,
};

export default Title;
