import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import menuOpenSvg from '@/assets/images/mobile-menu-open.svg';
import menuCloseSvg from '@/assets/images/mobile-menu-close.svg';

import Menu from './Menu';
import useStyle, { animationProps } from './styles';

const Mobile = ({ children, location }) => {
  const classes = useStyle();
  const [isOpenMenu, setIsOpenMenu] = useState(location.pathname === '/');
  const handleBtnClick = useCallback(() => setIsOpenMenu(!isOpenMenu), [isOpenMenu]);
  const closeMenu = useCallback(() => setIsOpenMenu(false), []);

  useEffect(() => {
    setIsOpenMenu(location.pathname === '/');
  }, [location.pathname]);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        <Link to="/stiftungszweck" className={classes.icon} onClick={closeMenu} />
        <button type="button" onClick={handleBtnClick} className={classNames(classes.button, isOpenMenu && classes.buttonClose)}>
          <img src={isOpenMenu ? menuCloseSvg : menuOpenSvg} alt="button" />
        </button>
      </div>
      <div className={classes.wrapper}>
        <Menu isOpenMenu={isOpenMenu} closeMenu={closeMenu} />
        <motion.div {...animationProps} animate={isOpenMenu ? 'pose1' : 'pose2'} className={classes.contentContainer}>
          {children}
        </motion.div>
      </div>
    </div>
  );
};

Mobile.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  children: PropTypes.element.isRequired,
};

export default Mobile;
