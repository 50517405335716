module.exports = {
  spacing: 8,

  menu: {
    item: {
      height: 70,
    },
  },

  button: {
    height: 50,
  },

  text: {
    main: {
      fontSize: 15,
      fontFamily: 'Work Sans',
      fontWeight: 400,
      lineHeight: 1.33,
      letterSpacing: 'normal',
      color: '#003a47',
    },
    logo: {
      fontSize: 24,
      fontFamily: 'Work Sans',
      fontWeight: 500,
      lineHeight: 1.17,
      letterSpacing: '-0.1px',
      color: 'rgba(0, 58, 71, 0.41)',
    },
    menuItem: {
      fontSize: 18,
      fontFamily: 'Work Sans',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '-0.3px',
    },
    link: {
      fontSize: 15,
      fontFamily: 'Work Sans',
      fontWeight: 500,
      llineHeight: 1.24,
      letterSpacing: 'normal',
      textDecoration: 'none',
      verticalAlign: 'bottom',
      color: '#00819e',
      '&:hover': {
        color: '#00b0d7',
      },
      '&:active': {
        color: '#005b6f',
      },
    },
    dropDown: {
      fontSize: 16,
      fontFamily: 'Work Sans',
      fontWeight: 500,
      letterSpacing: 'normal',
      lineHeight: 1.24,
      color: '#003a47',
    },
  },

  colors: {
    black: '#000',
    white: '#fff',
    contrast: '#007994',
    active: '#003a47',
    hover: '#005b6f',
    click: '#002b35',
    border: {
      light: '#d8e4ea',
      dark: '#003a47',
    },
    dropDown: 'rgba(0, 58, 71, 0.08)',
  },
};
