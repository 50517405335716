import { createUseStyles } from 'react-jss';

export default createUseStyles({
  ssrSplash: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10,
    backgroundColor: '#003a47',
  },
});
