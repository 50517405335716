export class CheckerUserTime {
  constructor({ timeOut, onTimeOut }) {
    this.timeOut = timeOut * 1000;
    this.onTimeOut = onTimeOut;
  }

  trigger() {
    if (this.timer) {
      this.timeStart = Date.now();
    }
  }

  start() {
    this.timer = setInterval(() => {
      if (Date.now() - this.timeStart > this.timeOut) {
        this.stop();
        this.onTimeOut();
      }
    }, 200);
  }

  stop() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
}
