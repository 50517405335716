import { colors } from '@/theme';

const { border, active, white, contrast } = colors;

export const animationProps = {
  variants: {
    position1: {
      background: white,
      borderTop: `1px solid ${border.light}`,
      borderBottom: `1px solid ${border.dark}`,
      color: contrast,
    },
    position2: {
      background: active,
      borderTop: `1px solid ${border.light}`,
      borderBottom: `1px solid ${border.light}`,
      color: white,
    },
  },
  transition: { duration: 0.5 },
  initial: false,
};
