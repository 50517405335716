import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Title from './Title';
import ContentContainer from './ContentContainer';

const Menu = ({ classes, children, location }) => {
  const { dataJson } = useStaticQuery(
    graphql`
      {
        dataJson {
          menu {
            id
            title
            to
          }
        }
      }
    `
  );

  return dataJson.menu.map(({ id, title, to }) => {
    const isOpen = location.pathname.includes(id);

    return (
      <Fragment key={id}>
        <Title
          type="button"
          disabled={isOpen}
          to={to}
          title={title}
          classNameWrapper={classes.link}
          className={classNames([isOpen || classes.titlePseudo, classes.title])}
        />
        <ContentContainer totalCount={dataJson.menu.length} isOpen={isOpen} location={location}>
          {children}
        </ContentContainer>
      </Fragment>
    );
  });
};

Menu.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.element.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
};

export default Menu;
