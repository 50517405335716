const React = require('react');
const { JssProvider, ThemeProvider } = require('react-jss');

const theme = require('./src/theme');
const GlobalStyles = require('./src/theme/GlobalStyles').default;
const Layout = require('./src/components/Layout').default;

exports.onInitialClientRender = () => {
  const ssStyles = window.document.getElementById('jss-server-side');
  if (ssStyles) {
    ssStyles.parentNode.removeChild(ssStyles);
  }
};

exports.wrapRootElement = ({ element }) => (
  <JssProvider id={{ minify: process.env.NODE_ENV === 'production' }}>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        {element}
      </>
    </ThemeProvider>
  </JssProvider>
);

exports.wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;
