import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import icon from '@/assets/images/u.svg';

const Uicon = forwardRef(({ classes }, ref) => (
  <div className={classes.container}>
    <img ref={ref} src={icon} alt="U" className={classes.icon} width="28" height="36" />
  </div>
));

Uicon.propTypes = { classes: PropTypes.objectOf(PropTypes.string).isRequired };

export default Uicon;
