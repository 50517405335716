export default ({ text, colors, spacing }) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    '@media (max-width: 800px)': {
      display: 'none',
    },
  },

  leftSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: [spacing * 5.75, 0, spacing * 4.75, spacing * 6.75],
  },

  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '50%',
    borderLeft: [3, 'solid', colors.border.light],
  },

  logoText: {
    '&>p': {
      margin: 0,
      ...text.logo,
    },
  },
});
