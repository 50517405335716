import { createUseStyles } from 'react-jss';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

export default createUseStyles(({ menu, colors, text, spacing }) => {
  const { hover, click, active } = colors;

  return {
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      overflow: 'hidden',
      zIndex: 5,
    },
    button: {
      display: 'flex',
      width: '100%',
      paddingLeft: spacing * 3.245,
      alignItems: 'center',
      height: menu.item.height,
      cursor: 'pointer',
      outline: 'none',
      border: [1, 'solid', '#d8e4ea'],
      borderLeft: 'none',
      borderRight: 'none',
      backgroundColor: active,
      color: colors.white,
      ...text.menuItem,
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: hover,
      },
      '&:active': {
        backgroundColor: click,
      },
      '&:focus': {
        outline: 'none',
      },
    },

    logoText: {
      margin: [spacing * 2.5, 0, spacing, spacing * 3.5],

      '&>p': {
        margin: 0,
        ...text.logo,
        fontSize: 20,
        lineHeight: 1.15,
        color: '#d8e4ea',
      },
    },
  };
});

export const Item = motion(Link);

export const parentAnimationProps = {
  variants: {
    enter: {
      height: 'auto',
      transition: { staggerChildren: 0.05 },
    },
    exit: {
      height: 0,
      transition: {
        height: { delay: 0.2 },
        staggerChildren: 0.02,
      },
    },
  },
  initial: false,
};
export const childAnimationProps = {
  variants: {
    enter: { x: 0, opacity: 1 },
    exit: { x: '100%', opacity: 0 },
  },
  initial: false,
};
