export const getHeight = () => (typeof window !== 'undefined' && window.innerHeight) || 0;
export const getWidth = () => (typeof window !== 'undefined' && window.innerWidth) || 0;

export const flatten = (arr = []) => arr.reduce((acc, item = []) => [...acc, ...item], []);

export const getIconList = (height, width, size) => {
  const column = Math.floor(width / size);
  const offsetX = (getWidth() - column * size) / 2;
  const row = Math.floor(height / size);
  const offsetY = (getHeight() - row * size) / 2;

  const icons =
    typeof window !== 'undefined'
      ? Array(column)
          .fill(0)
          .map(
            (_, i) =>
              typeof window !== 'undefined' &&
              Array(row)
                .fill(0)
                .map((__, j) => ({
                  x: size * i + offsetX,
                  y: size * j + offsetY,
                  corner: 0,
                }))
          )
      : [];

  return flatten(icons);
};

const getCorner = (clientX, clientY, X, Y) => {
  const x = clientX - X;
  const y = clientY - Y;
  const corner = Math.atan(x / y) * (180 / Math.PI);

  switch (true) {
    case x > 0 && y < 0:
      return corner * -1;
    case x > 0 && y === 0:
      return 90;
    case x > 0 && y > 0:
      return 180 - corner;
    case x === 0 && y > 0:
      return 180;
    case x < 0 && y > 0:
      return -1 * (180 + corner);
    case x < 0 && y === 0:
      return -90;
    case x < 0 && y < 0:
      return -1 * corner;
    default:
      return 0;
  }
};

export const handlerIcons = (icons, clientX, clientY, size) =>
  icons.map(({ x, y }) => {
    const X = x + size / 2;
    const Y = y + size / 2;

    return {
      x,
      y,
      corner: getCorner(clientX, clientY, X, Y),
    };
  });

export const throttle = (handler, time) => {
  let args;
  let timer;

  return function wrapper(...arg) {
    if (!timer) {
      handler(...arg);
      timer = setInterval(() => {
        if (args) {
          handler(...args);
          args = null;
        } else {
          clearInterval(timer);
          timer = null;
        }
      }, time);
    } else {
      args = arg;
    }
  };
};
